import gql from 'graphql-tag';

export const ADMIN_TOGGLE_SIGN_IN_TOKEN_ENABLED = gql`
  mutation adminToggleSignInTokenEnabled($email: String, $userId: String) {
    adminToggleSignInTokenEnabled(email: $email, userId: $userId) {
      success
      error
      token
      isEnabled
    }
  }
`;

export const ADMIN_REFRESH_SIGN_IN_TOKEN = gql`
  mutation adminRefreshSignInToken($email: String, $userId: String) {
    adminRefreshSignInToken(email: $email, userId: $userId) {
      success
      error
      token
      isEnabled
    }
  }
`;