import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '../../../core/ui/atoms/Button';
import Switch from '../../../core/ui/atoms/Switch';
import PropTypes from 'prop-types';
import { useMutation } from 'react-apollo';
import { SINGLE_USER } from '../../api/apollo/queries';
import {
  ADMIN_TOGGLE_SIGN_IN_TOKEN_ENABLED,
  ADMIN_REFRESH_SIGN_IN_TOKEN,
} from '../../api/apollo/mutations';

const UserToken = ({ email, userId, authToken, authTokenEnabled }) => {
  const [isProcessing, setIsProcessing] = useState(false);

  const [adminToggleSignInTokenEnabled] = useMutation(
    ADMIN_TOGGLE_SIGN_IN_TOKEN_ENABLED
  );
  const [adminRefreshSignInToken] = useMutation(ADMIN_REFRESH_SIGN_IN_TOKEN);

  const toggleSignInTokenEnabled = async () => {
    try {
      await adminToggleSignInTokenEnabled({
        variables: {
          email,
          // userId,
        },
        refetchQueries: [{ query: SINGLE_USER, variables: { _id: userId } }],
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  const refreshSignInToken = async () => {
    try {
      await adminRefreshSignInToken({
        variables: {
          email,
          // userId,
        },
        refetchQueries: [{ query: SINGLE_USER, variables: { _id: userId } }],
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <UserCard>
      <p>
        <span>Токен: </span>
        {authToken || 'Нет'}
      </p>
      <Container>
        <Button disabled={isProcessing} onClick={refreshSignInToken}>
          Сгенерировать
        </Button>
        <Switch
          onChange={toggleSignInTokenEnabled}
          checked={authTokenEnabled}
          label={'Включить вход по токену'}
          disabled={isProcessing}
        />
      </Container>
    </UserCard>
  );
};

UserToken.propTypes = {
  email: PropTypes.string,
  userId: PropTypes.string,
  authToken: PropTypes.string,
  authTokenEnabled: PropTypes.bool,
};

const UserCard = styled.div`
  gap: 10px;
  width: 500px;
  span {
    font-weight: 800;
  }
`;

const Container = styled.div`
  display: flex;
  gap: 20px;
`;

export default UserToken;
